import { Button } from "components/Button";
import { ContactTypeCode } from "components/JsonForm/theme/widgets/ApplicationContactsWidget/types";
import { getHiddenFieldsByContactType, ApplicationContact, ContactType, isContactDataEmpty } from "components/utils/contacts";
import { formatPhoneNumber } from "components/utils/format";
import { capitalize, isEmpty } from "lodash";
import { useState } from "react";
import { Card, Collapse } from "react-bootstrap";
import { Avatar } from "components/Avatar";
import { Icon } from "components/Icon";
import { ContactDetails } from "./ContactDetails";
import { ContactEdit } from "./ContactEdit";
import { getContactTypeByCode, getAvatarColor, getAvatarText, getContactName, EMPTY_VALUE } from "./utils";
import { SmallText } from "./SmallText";
import { TitleText } from "./TitleText";
import { IconWithText } from "./IconWithText";
import classNames from "classnames";
import { ContactRequirements } from "types/ContactRequirements";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import useMediaQuery from "components/utils/useMediaQuery";

import "./style.scss";

export const ContactCard = ({
    contact,
    contactTypeCode,
    applicationNumber,
    isEditable = false,
    isCustomerContact = false,
    isAdditionalContact = false,
    isAppSubmitted = false,
    requirements,
    hideAcctNumberOnPortal = false,
    hideSaveContactSection = false,
    onDeleteContact,
    onContactChanged,
}: {
    contact: ApplicationContact;
    contactTypeCode?: ContactTypeCode;
    applicationNumber?: string;
    isEditable?: boolean;
    isCustomerContact?: boolean;
    isAdditionalContact?: boolean;
    isAppSubmitted?: boolean;
    hideAcctNumberOnPortal?: boolean;
    hideSaveContactSection?: boolean;
    requirements: ContactRequirements;
    onDeleteContact?: (contactNumber: string) => void;
    onContactChanged?: () => void;
}) => {
    const [activeContactDetails, setActiveContactDetails] = useState<ApplicationContact | null>(null);
    const [activeContactEdit, setActiveContactEdit] = useState<ApplicationContact | null>(null);
    const contactType = getContactTypeByCode(Number(contactTypeCode));
    const isEmptyContact = isContactDataEmpty(contact);

    if (!isEditable && isEmptyContact) {
        return null;
    }

    return (
        <>
            {activeContactDetails && !isEmptyContact && (
                <ContactDetails
                    contact={activeContactDetails}
                    isAdditionalContact={isAdditionalContact}
                    hideAcctNumberOnPortal={hideAcctNumberOnPortal}
                    requirements={requirements}
                    onClose={() => setActiveContactDetails(null)}
                />
            )}
            {activeContactEdit && (
                <ContactEdit
                    contact={activeContactEdit}
                    newContact={isEmptyContact}
                    newContactTypeCode={contactTypeCode}
                    applicationNumber={applicationNumber}
                    isAdditionalContact={isAdditionalContact}
                    isCustomerContact={isCustomerContact}
                    isAppSubmitted={isAppSubmitted}
                    requirements={requirements}
                    hideAcctNumberOnPortal={hideAcctNumberOnPortal}
                    hideSaveContactSection={hideSaveContactSection}
                    onClose={() => setActiveContactEdit(null)}
                    onSaved={onContactChanged}
                />
            )}
            <Card className="contact-card flex-grow-1">
                <Card.Body className="d-flex flex-column gap-2">
                    {!isEmptyContact ? (
                        <Contact
                            contact={contact}
                            isEditable={isEditable}
                            requirements={requirements}
                            isAdditionalContact={isAdditionalContact}
                            setActiveContactEdit={setActiveContactEdit}
                            setActiveContactDetails={setActiveContactDetails}
                            onDeleteContact={onDeleteContact}
                        />
                    ) : (
                        <EmptyContact contact={contact} contactType={contactType} setActiveContactEdit={setActiveContactEdit} />
                    )}
                </Card.Body>
            </Card>
        </>
    );
};

const Contact = ({
    contact,
    isEditable = false,
    requirements,
    isAdditionalContact = false,
    setActiveContactEdit,
    setActiveContactDetails,
    onDeleteContact,
}: {
    contact: ApplicationContact;
    isAdditionalContact?: boolean;
    isEditable?: boolean;
    requirements: ContactRequirements;
    setActiveContactEdit: React.Dispatch<React.SetStateAction<ApplicationContact | null>>;
    setActiveContactDetails: React.Dispatch<React.SetStateAction<ApplicationContact | null>>;
    onDeleteContact?: (contactNumber: string) => void;
}) => {
    return (
        <>
            <div className="d-flex align-items-center justify-content-between gap-2">
                <div className="d-flex gap-2 align-items-center">
                    <ContactAvatar contact={contact} />
                    <ContactTitle contact={contact} isAdditionalContact={isAdditionalContact} />
                </div>
                <div className="d-flex gap-2">
                    {onDeleteContact && (
                        <Button
                            variant="outline-secondary"
                            title="Delete contact"
                            onClick={() => onDeleteContact(contact.contactNumber)}
                            aria-haspopup="dialog"
                        >
                            <Icon icon={["fal", "trash-can"]} />
                        </Button>
                    )}
                    {isEditable && (
                        <Button
                            variant="outline-secondary"
                            title="Edit contact"
                            onClick={() => setActiveContactEdit(contact)}
                            aria-haspopup="dialog"
                        >
                            <Icon icon={["fal", "pen"]} />
                        </Button>
                    )}
                </div>
            </div>
            <div className="d-flex flex-column gap-2">
                <hr className="my-0"></hr>
                <ContactMainData contact={contact} requirements={requirements} />
            </div>
            <div className="d-flex flex-column gap-2 justify-content-end align-self-stretch flex-grow-1">
                <hr className="my-0"></hr>
                <Button
                    className="align-self-stretch d-flex justify-content-center align-items-center gap-2"
                    variant="outline-secondary"
                    onClick={() => setActiveContactDetails(contact)}
                    aria-haspopup="dialog"
                >
                    <span>More info</span>
                    <Icon icon={["fal", "arrow-right"]} />
                </Button>
            </div>
        </>
    );
};

const EmptyContact = ({
    contact,
    contactType,
    setActiveContactEdit,
}: {
    contact: ApplicationContact;
    contactType: ContactType;
    setActiveContactEdit: React.Dispatch<React.SetStateAction<ApplicationContact | null>>;
}) => {
    return (
        <>
            <div className="d-flex align-items-center align-self-stretch gap-2">
                <div className="d-flex flex-column">
                    <h3 className="text-body small text-wrap text-break m-0 text-capitalize">{contactType} Contact</h3>
                </div>
            </div>
            <div className="d-flex flex-column">
                <hr className="my-2" />
                <div className="d-flex flex-column place-content-center align-items-center py-5">
                    <Button
                        className="align-self-stretch d-flex justify-content-center align-items-center gap-2"
                        variant="primary"
                        onClick={() => setActiveContactEdit(contact)}
                        aria-haspopup="dialog"
                    >
                        <Icon icon={["fal", "square-plus"]} />
                        <span>Add Contact</span>
                    </Button>
                </div>
            </div>
        </>
    );
};

export const ContactCardForHeader = ({ contact, requirements }: { contact: ApplicationContact; requirements: ContactRequirements }) => {
    const APP_CONTACT_DETAILS_ID = "contact-details-block";

    const isMobile = useMediaQuery("(max-width: 768px)");

    const [expanded, setExpanded] = useState(!isMobile);

    return (
        <Card
            className={classNames("contact-card flex-grow-1", {
                "desktop-header": !isMobile,
            })}
        >
            <Card.Body className="d-flex flex-column">
                <div className="d-flex align-items-center align-self-stretch gap-2">
                    <ContactAvatar contact={contact} />
                    <ContactTitleForHeader contact={contact} />
                    {isMobile && (
                        <Button
                            onClick={() => setExpanded(!expanded)}
                            variant="outline-secondary"
                            size="sm"
                            className={classNames("btn-sm my-auto ms-auto")}
                            aria-expanded={expanded}
                            aria-controls={APP_CONTACT_DETAILS_ID}
                            aria-label={expanded ? "Hide premise contact details" : "Show premise contact details"}
                        >
                            <FontAwesomeIcon className="ms-auto" icon={["fal", expanded ? "chevron-up" : "chevron-down"]} size={"lg"} />
                        </Button>
                    )}
                </div>
                <Collapse in={expanded || !isMobile}>
                    <div id={APP_CONTACT_DETAILS_ID}>
                        <div className="d-flex flex-column mt-2 gap-2">
                            <hr className="my-0"></hr>
                            <ContactMainData contact={contact} requirements={requirements} isHeader={true} />
                        </div>
                    </div>
                </Collapse>
            </Card.Body>
        </Card>
    );
};

const ContactAvatar = ({ contact }: { contact: ApplicationContact }) => {
    const avatarColor = getAvatarColor(contact);
    const avatarText = getAvatarText(contact);

    return <Avatar color={avatarColor} text={avatarText} />;
};

const ContactTitle = ({ contact, isAdditionalContact = false }: { contact: ApplicationContact; isAdditionalContact?: boolean }) => {
    const contactType = getContactTypeByCode(Number(contact.contactType));
    const contactTitle = getContactName(contact);
    const contactSubTitle = isAdditionalContact ? contact.contactTitle ?? EMPTY_VALUE : capitalize(contactType) + " Contact";

    return (
        <div className="d-flex flex-column">
            <h3 className="text-body small text-wrap text-break m-0 fw-normal">{contactSubTitle}</h3>
            <TitleText>{contactTitle}</TitleText>
        </div>
    );
};

const ContactTitleForHeader = ({ contact }: { contact: ApplicationContact }) => {
    const contactType = getContactTypeByCode(Number(contact.contactType));
    const contactTitle = getContactName(contact);
    const contactSubTitle = capitalize(contactType) + " Contact";

    return (
        <div className="d-flex flex-column">
            <h2 className="text-body small text-wrap text-break m-0 fw-normal">{contactSubTitle}</h2>
            <TitleText>{contactTitle}</TitleText>
        </div>
    );
};

const ContactMainData = ({
    contact,
    requirements,
    isHeader,
}: {
    contact?: ApplicationContact;
    requirements: ContactRequirements;
    isHeader?: boolean;
}) => {
    if (!contact) {
        return null;
    }

    const contactType = getContactTypeByCode(Number(contact.contactType));
    const hiddenFields = getHiddenFieldsByContactType(requirements.hiddenFields, contactType);
    const contactEmail = !isEmpty(contact.email) ? <a href={`mailto:${contact.email}`}>{contact.email}</a> : EMPTY_VALUE;
    const contactPhone = !isEmpty(contact.phone) ? <a href={`tel:${contact.phone}`}>{formatPhoneNumber(contact.phone)}</a> : EMPTY_VALUE;
    const contactAddress =
        !isEmpty(contact.address) || !isEmpty(contact.city)
            ? [contact.address, contact.city].filter((i) => !isEmpty(i)).join(", ")
            : EMPTY_VALUE;
    const contactCompany = !isEmpty(contact.company) ? contact.company : EMPTY_VALUE;

    const showEmail = !hiddenFields.includes("email");
    const showPhone = !hiddenFields.includes("phone");
    const showCompany = !hiddenFields.includes("company");
    const showAddress = !hiddenFields.includes("address");

    return (
        <>
            {showEmail && (
                <IconWithText icon={"envelope"} title="Email">
                    <SmallText>{contactEmail}</SmallText>
                </IconWithText>
            )}
            {showPhone && (
                <IconWithText icon={"phone"} title="Phone">
                    <SmallText>{contactPhone}</SmallText>
                </IconWithText>
            )}
            {showAddress && isHeader && (
                <IconWithText icon={"location-dot"} title="Address">
                    <SmallText>{contactAddress}</SmallText>
                </IconWithText>
            )}
            {showCompany && (
                <IconWithText icon={"buildings"} title="Company">
                    <SmallText>{contactCompany}</SmallText>
                </IconWithText>
            )}
        </>
    );
};
